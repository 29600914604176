<template>
  <div>
    <v-dialog v-model="confirm_dialog" scrollable width="40%">
      <v-card>
        <v-card-title
          ><h2>{{ getConfirmTitle() }}</h2></v-card-title
        >
        <v-card-text
          ><h3>{{ getConfirmMessage() }}</h3>

          <v-checkbox
            v-if="
              edit_item != null &&
              ez_invoice_status_enum.Unknown == edit_item.invoice_status
            "
            label="立刻開發票"
            v-model="edit_item.immediate"
          ></v-checkbox
        ></v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="
              font-weight-bold
              text-xs
              btn-default
              bg-gradient-secondary
              px-6
              py-2
              mt-2 mt-8
            "
            @click="confirm_dialog = false"
          >
            {{ $t("Close") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="
              font-weight-bold
              text-xs
              bg-gradient-primary
              py-5
              px-6
              mt-6
              mb-2
              me-2
              ms-auto
              text-white
            "
            @click="onActionConfirmed()"
          >
            {{ $t("Run") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-text class="px-4 py-0">
        <v-data-table
          :headers="headers"
          :items="orders"
          :search="search"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
          @click:row="rowClick"
          :options.sync="options"
          :server-items-length="items_count"
          multi-sort
          :loading="loading"
        >
          <template v-slot:loading>
            {{ $t("Loading...") }}
          </template>
          <template v-slot:top>
            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  v-model="search"
                  placeholder="Search"
                >
                  <template slot="prepend-inner">
                    <v-icon color="#adb5bd" size=".875rem"
                      >fas fa-search</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.pay="{ item }">
            {{ item.pay | getText(payment_enum_str) }}
          </template>
          <template v-slot:item.end_date="{ item }">
            {{ getEndDate(item.end_date) }}
          </template>
          <template v-slot:item.invoice_create="{ item }">
            {{ item.invoice_create | dateOnly }}
          </template>
          <template v-slot:item.create_time="{ item }">
            {{ item.create_time | dateFormatDash }}
          </template>
          <template v-slot:item.valid="{ item }">
            {{ item.valid | dateFormatDash }}
          </template>
          <template v-slot:item.expire="{ item }">
            {{ getExpireStr(item) }}
          </template>
          <template v-slot:item.transaction_type="{ item }">
            {{
              item.transaction_type | getText(point_transaction_type_enum_str)
            }}
          </template>
          <template v-slot:item.type="{ item }">
            {{ item.type | getText(point_type_enum_str) }}
          </template>

          <template v-slot:item.status="{ item }"
            >{{ getStatusStr(item.status) }}
          </template>
          <template v-slot:item.shop_type="{ item }">
            {{ item.shop_type | getText(shop_type_enum_str) }}
          </template>
          <template v-slot:item.invoice_status="{ item }">
            {{ item.invoice_status | getText(ez_invoice_status_enum_str) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mr-2"
                  size="25"
                  v-on="on"
                  @click.stop="
                    actionConfirm(
                      item,
                      item.invoice_status == ez_invoice_status_enum.Unknown
                    )
                  "
                  :color="
                    item.invoice_status == ez_invoice_status_enum.Unknown
                      ? 'primary'
                      : 'grey'
                  "
                  >mdi-plus-thick</v-icon
                >
              </template>
              <span>{{ $t("New Invoice") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mr-2"
                  v-on="on"
                  size="25"
                  @click.stop="
                    actionConfirm(
                      item,
                      item.invoice_status == ez_invoice_status_enum.Init
                    )
                  "
                  :color="
                    item.invoice_status == ez_invoice_status_enum.Init
                      ? 'primary'
                      : 'grey'
                  "
                  >mdi-receipt-text-arrow-right</v-icon
                >
              </template>
              <span>{{ $t("Trigger Invoice") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mr-2"
                  size="25"
                  v-on="on"
                  @click.stop="
                    actionConfirm(
                      item,
                      item.invoice_status == ez_invoice_status_enum.Invoice
                    )
                  "
                  :color="
                    item.invoice_status == ez_invoice_status_enum.Invoice
                      ? 'primary'
                      : 'grey'
                  "
                  >mdi-close</v-icon
                >
              </template>
              <span>{{ $t("Void Invoice") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#cb0c9f"
              v-model="page"
              :length="getPageCount()"
              circle
              :total-visible="15"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import CommonUtility from "@/util/CommonUtility.js";
import HttpCommonMixin from "./HttpCommonMixin.vue";
import {
  shop_type_enum,
  shop_type_enum_str,
  shop_status_enum_str,
  payment_enum_str,
  history_type_enum,
  point_status_enum_str,
  point_type_enum,
  point_type_enum_str,
  point_transaction_type_enum_str,
  ez_invoice_status_enum,
  ez_invoice_status_enum_str,
} from "@/definitions.js";

export default {
  props: {
    history_type: {
      type: Number,
      default: 0,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  name: "shop-table-orders",
  data(vm) {
    return {
      loading: false,
      point_status_enum_str: point_status_enum_str,
      point_type_enum_str: point_type_enum_str,
      point_transaction_type_enum_str: point_transaction_type_enum_str,
      shop_type_enum: shop_type_enum,
      shop_type_enum_str: shop_type_enum_str,
      payment_enum_str: payment_enum_str,
      ez_invoice_status_enum: ez_invoice_status_enum,
      ez_invoice_status_enum_str: ez_invoice_status_enum_str,
      page: 1,
      pageCount: 0,
      items_count: 0,
      itemsPerPage: 10,
      orders: [],
      options: {},
      search: "",
      shop_headers: [
        {
          text: vm.$t("Order ID"),
          value: "order_id",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Pay",
          value: "pay",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: vm.$t("Name"),
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Status",
          value: "status",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Start Date",
          value: "start_date",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "End Date",
          value: "end_date",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Total",
          value: "total",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Tax",
          value: "tax",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: vm.$t("Tax Include"),
          value: "tax_include",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: vm.$t("Invoice Number"),
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: vm.$t("Invoice status"),
          value: "invoice_status",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: vm.$t("Invoice Create"),
          value: "invoice_create",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
      point_headers: [
        {
          text: vm.$t("Point Type"),
          value: "type",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: vm.$t("Amount"),
          value: "amount",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: vm.$t("Status"),
          value: "status",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: vm.$t("Transaction Type"),
          value: "transaction_type",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: vm.$t("Create Time"),
          value: "create_time",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "valid",
          value: "valid",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "expire",
          value: "expire",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
      confirm_dialog: false,
      edit_item: {},
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
    headers() {
      let tmp = null;
      if (this.history_type == history_type_enum.PointUse) {
        tmp = JSON.parse(JSON.stringify(this.point_headers));
      } else if (this.history_type == history_type_enum.All) {
        tmp = JSON.parse(JSON.stringify(this.shop_headers));
        if (this.readOnly == false) {
          tmp.push({
            text: "actions",
            value: "actions",
            class: "text-secondary font-weight-bolder opacity-7",
          });
        }
        return tmp;
      } else {
        tmp = JSON.parse(JSON.stringify(this.shop_headers));
      }

      return tmp;
    },
  },
  watch: {
    options() {
      this.getData();
    },
    history_type() {
      this.getData();
    },
  },
  mixins: [HttpCommonMixin],
  methods: {
    ...mapGetters(["getToken"]),
    getData() {
      switch (this.history_type) {
        case history_type_enum.Recharge:
          this.getTableData("/api/v1/recharges/", shop_type_enum.Points);
          break;
        case history_type_enum.Shop:
          this.getTableData("/api/v1/shops/", shop_type_enum.Information);
          break;
        case history_type_enum.PointUse:
          this.getTableData("/api/v1/points/", shop_type_enum.Points);
          break;
        default:
          this.getTableData("/api/v1/shops/", shop_type_enum.Unknown);
          break;
      }
    },
    getPageCount() {
      return Math.ceil(this.items_count / this.itemsPerPage);
    },
    getEndDate(date_str) {
      if (this.history_type == history_type_enum.Recharge) {
        return "----------";
      }
      return date_str;
    },
    onFinal() {
      this.loading = false;
    },
    getTableData(action, shop_type) {
      const url = `${process.env.VUE_APP_SERVER_URL}${action}`;
      let params = {
        page: this.page,
        size: this.itemsPerPage,
        shop_type: shop_type,
      };
      const { sortBy, sortDesc } = this.options;
      if (sortBy.length > 0) {
        const sortParas = CommonUtility.getSortParas(sortBy, sortDesc);
        params.ordering = sortParas;
      }
      this.loading = true;
      this.doHttpGet(url, params, this.setResult, null, this.onFinal);
    },

    setResult(data) {
      console.log(data.items);
      this.items_count = data.total;
      this.orders = data.items;
    },
    rowClick(item, row) {
      console.debug("row clicked", item, "row:", row);
      this.toDetail(item);
    },

    toDetail(item) {
      const order_id = item.order_id;
      this.$router.push({
        path: "/pages/customer/order-done",
        query: { order_id: order_id, back: 1 },
      });
    },
    getStatusStr(status) {
      if (this.history_type == history_type_enum.PointUse) {
        return CommonUtility.getText(status, point_status_enum_str);
      }
      return CommonUtility.getText(status, shop_status_enum_str);
    },
    getExpireStr(item) {
      if (item.type == point_type_enum.Point) {
        return "";
      }
      return dayjs(item.expire).format("YYYY-MM-DD HH:mm:ss");
    },
    edit(item) {
      console.log("edit item ", item);
    },
    onCommitInvoice(data) {
      const message = data.message;
      alert(message);
      this.edit_item = null;
      this.getData();
    },
    actionConfirm(item, allowConfirm) {
      if (allowConfirm == false) {
        return;
      }
      item["immediate"] = false;

      this.confirm_dialog = true;
      this.edit_item = item;
    },
    getConfirmTitle() {
      const item = this.edit_item;

      if (item == null) {
        return "";
      }
      switch (item.invoice_status) {
        case ez_invoice_status_enum.Init:
          return "觸發開立";
        case ez_invoice_status_enum.Unknown:
          return "新增發票";
        case ez_invoice_status_enum.Invoice:
          return "註銷發票";
        default:
          return "";
      }
    },
    getConfirmMessage() {
      const item = this.edit_item;
      if (item == null) {
        return "";
      }
      switch (item.invoice_status) {
        case ez_invoice_status_enum.Unknown:
          return "新增開立發票嗎?";
        case ez_invoice_status_enum.Init:
          return `觸發開立發票 訂單${item.order_id}?`;
        case ez_invoice_status_enum.Invoice:
          return `註銷發票? :${item.invoice_number}`;
        default:
          return "";
      }
    },
    onActionConfirmed() {
      this.confirm_dialog = false;
      switch (this.edit_item.invoice_status) {
        case ez_invoice_status_enum.Unknown:
          this.createInvoice(this.edit_item);
          break;
        case ez_invoice_status_enum.Init:
          this.commitInvoice(this.edit_item);
          break;
        case ez_invoice_status_enum.Invoice:
          this.voidInvoice(this.edit_item);
          break;
        default:
          break;
      }
    },
    createInvoice(item) {
      if (item.invoice_status == ez_invoice_status_enum.Unknown) {
        // TODO 以後有立刻開立的功能再加
        console.log("create item ", item.id);
        const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/invoices/${item.id}/create`;
        let data = {
          immediate: item.immediate,
        };

        this.doHttpPost(url, data, this.onCommitInvoice, null, this.onFinal);
      } else {
        console.log("Ignore the commitInvoice item ", item);
      }
    },
    commitInvoice(item) {
      if (item.invoice_status == ez_invoice_status_enum.Init) {
        console.log("commitInvoice item ", item.id);
        const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/invoices/${item.id}/commit`;
        this.doHttpPost(url, {}, this.onCommitInvoice, null, this.onFinal);
      } else {
        console.log("Ignore the commitInvoice item ", item);
      }
    },
    voidInvoice(item) {
      if (item.invoice_status == ez_invoice_status_enum.Invoice) {
        console.log("voidInvoice item ", item);
        const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/invoices/${item.id}/void`;
        this.doHttpPost(url, {}, this.onCommitInvoice, null, this.onFinal);
      } else {
        console.log("Ignore the voidInvoice item ", item);
      }
    },
  },
};
</script>
